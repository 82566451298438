import React from 'react'
import mail from "../img/mail-icon.png"
import phone from "../img/call-icon.png"
import locationPic from "../img/location-icon.png"

export default function Footer() {
  return (
    <footer>
        <div className='footer-first-div fade-in from-bottom'>
            <div>
                    <a href='mailto:peacemakerautos@gmail.com'>
                        <img src={mail} alt="mail icon"/><br/>
                        peacemakerautos@gmail.com
                    </a>
                </div>
            <div>
                <a href='tel:+14163007648'>
                <img src={phone} alt='phone-icom'/><br/>
                1-416-300-7648
                </a>
            </div>
            <div>
                <a target="_blank" href='https://www.google.ca/maps/place/11672+Trafalgar+Rd,+Georgetown,+ON+L7G+4S4/@43.640876,-79.9401849,18z/data=!3m1!4b1!4m6!3m5!1s0x882b72b85e15b5f5:0xa46d26999fb8592e!8m2!3d43.6409173!4d-79.9399063!16s%2Fg%2F11csjpm1l8?entry=ttu'>
                    <img src={locationPic} alt='location-icon'/><br/>
                    11672 Trafalgar Road Georgetown ON. L7G4S4
                </a>
            </div>
        </div>

        <div className='footer-second-div'>
            <div>
                <div className='logodiv' title="Customer Satifaction Guaranteed">
                    <h2>PeaceMaker</h2>
                    <h2>Autos</h2>
                </div>
                <p className='footer-p'>
                Experience Excellence in Automotive Solutions with PeaceMaker Autos LTD. 
                As your trusted partner, we are dedicated to delivering quality, integrity, 
                and customer satisfaction. From buying your cars and car parts to providing 
                reliable salvaged components, our commitment is unwavering. Join our automotive 
                family and let PeaceMaker Autos LTD redefine your experience on the road.
                 Your peace of mind is our promise.
                </p>
            </div>

            <div>
                <h2>Custom Links</h2>
                <ul className='footer-list'>
                    <li>
                        <a href='#about' style={{color:'#BDBDBD', textDecoration:'none'}}>About Us </a>
                    </li>
                    <li title='We deliver within Canada in about 7 days, Contact Us for more informaton'>
                        Delivery Information
                    </li>
                    <li title='All Information sent is handled by our team'>
                        Privacy Policy 
                    </li>
                    <li title='Contact Us for more information'>
                        Terms & Conditions
                    </li>
                    <li>
                       <a href='#contact' style={{color:'#BDBDBD', textDecoration:'none'}}> Contact Us</a>
                    </li>
                    <li>
                        Site Map
                    </li>
                </ul>
            </div>

            <div>
                <h2>
                    Address
                </h2>
                <p className='footer-p'>11672 Trafalgar Road Georgetown ON. L7G4S4</p>

                <h2>Need Help?</h2>
                <a className='footer-p' href='tel:+14163007648' style={{textDecoration:"none"}}>Call Us</a>

                <h2>Products & Sales</h2>
                <p className='footer-p'>Automobile Services</p>
            </div>
        </div>
        <div className='copyright-div'>© 2023 PeaceMaker Auto LTD.</div>
    </footer>
  )
}
