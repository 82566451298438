import React from 'react'
import PhoneMenu from './PhoneMenu'

export default function () {
  return (
    <header>
        <div className='first-header-div'>
            <div className='logodiv' title="Customer Satifaction Guaranteed">
              <h2>PeaceMaker</h2>
              <h2>Autos</h2>
            </div>

            <div className='middle-div'>
                1-416-300-7648 <br/>
                peacemakerautos@gmail.com
            </div>

            <a className='first-header-contact' href="mailto: peacemakerautos@gmail.com">
                Email Us
            </a>
        </div>

        <div className='second-header-div'>
                <a href=''>Home</a>
                <a href='#about'>About Us</a>
                <a href='#gallery'>Gallery</a>
                <a href='#contact'>Contact</a>
        </div>
      <PhoneMenu/>
    </header>
  )
}
