import React, { useState } from 'react'
import MenuIcon from "../img/menu-btn.png"
export default function PhoneMenu() {
    const [showMenu, setShowMenu]= useState(false)
    let navClasses = "phoneMenu"
    let menu
    if(showMenu){
        navClasses = "phoneMenu open"
        
    }
    
    
    return (
        <span>
           <div className='phoneLogo' title="Customer Satifaction Guaranteed">
              <h2>PeaceMaker</h2>
              <h2>Autos</h2>
            </div>
            <span className="MenuBottonSpan"  >
            <img src={MenuIcon} alt="menu" className="MenuBotton" onClick={()=>setShowMenu(!showMenu)} />
            {menu}
            </span>
            
            <nav className={navClasses} id="phoneMenuId">
                <div className="subPM">
                    <div className="phoneLdiv firstNav">
                    <div className='logodiv' title="Customer Satifaction Guaranteed">
                        <h2>PeaceMaker</h2>
                        <h2>Autos</h2>
                    </div>
                    </div>
                    <div className="phoneLdiv"> <a href="" className="phoneL"  onClick={()=>setShowMenu(!showMenu)}>HOME</a></div>
                    <div className="phoneLdiv"> <a href="#about" className="phoneL"  onClick={()=>setShowMenu(!showMenu)}>ABOUT </a></div>
                    <div className="phoneLdiv"> <a href="#gallery" className="phoneL"  onClick={()=>setShowMenu(!showMenu)}>GALLERY</a></div>
                    <div className="phoneLdiv"> <a href="#contact" className="phoneL"  onClick={()=>setShowMenu(!showMenu)}>CONTACT</a></div>
                    <div className="phoneLdiv"> <a href="mailto: peacemakerautos@gmail.com" className="phoneL"  onClick={()=>setShowMenu(!showMenu)}>EMAIL US</a></div>
                </div>
            
            
            <div className='copy-phone'>© 2023 PeaceMaker Auto LTD.</div>
            </nav>
        
        </span>
        
    )
}
