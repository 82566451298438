import './App.css';
import Header from './component/Header';
import Main from './component/Main'
import { useEffect } from 'react';

function App() {

  useEffect(() => {
        var bodyS = document.body.style
        bodyS.overflowX ="hidden"
        const appearOptions = {
            root:null,
            threshold:0,
            rootMargin:"0px 0px -150px 0px"
        }       
      
        const faders = document.querySelectorAll(".fade-in")
        const sliders = document.querySelectorAll(".slide-in")
        const appearOnScroll = new IntersectionObserver
        (function(
            entries,
            appearOnScroll
        ){
            entries.forEach(entry => {
                if(!entry.isIntersecting){
                    return
                }else{
                      entry.target.classList.add("appear")
                      appearOnScroll.unobserve(entry.target)
                }
        
            })
        },appearOptions)
        
        
        faders.forEach(fader=>{
            appearOnScroll.observe(fader)
        })
      
        sliders.forEach(slider=>{
            appearOnScroll.observe(slider)
        })
  
  },[])


  return (
    <div className="App">
        <Header/>
        <Main/>
    </div>
  );
}




  
 



export default App;
