import React, { useEffect } from 'react'
import peace1 from "../img/peace1.jpeg"
import peace2 from "../img/peace2.jpeg"
import peace3 from "../img/peace3.jpeg"
import peace4 from "../img/peace4.jpeg"
import peace5 from "../img/peace5.jpeg"
import peace6 from "../img/peace6.jpeg"
import peace7 from "../img/peace7.jpeg"
import peace8 from "../img/peace8.jpeg"
import peace9 from "../img/peace9.jpeg"
import peace10 from "../img/peace10.jpeg"


export default function Gallery() {

  useEffect(() => {
    const buttons = document.querySelectorAll("[data-carousel-button]")

    buttons.forEach(button => {
        button.addEventListener("click", () => {
            const offset = button.dataset.carouselButton === "next" ? 1 : -1
            const slides = button.closest("[data-carousel]").querySelector("[data-slides]")

            const activeSlide = slides.querySelector("[data-active]")
            let newIndex = [...slides.children].indexOf(activeSlide) + offset
            if (newIndex < 0) newIndex = slides.children.length - 1
            if (newIndex >= slides.children.length) newIndex = 0

            slides.children[newIndex].dataset.active = true
            delete activeSlide.dataset.active
        })
    })
  },[])
  return (
    <div className='gallery' id='gallery'>
        <h2>GALLERY <span className='white-back'>BOX</span></h2>

        <div className='gallery-flex'>
            <div className='gallery-list fade-in from-left'>
                <ul>
                    <li>
                        WE BUY USED CARS
                    </li>
                    <li>
                        WE PURCHASE OLD OR DAMAGED VEHICLES 
                    </li>
                    <li>
                        WE DISMANTLE VEHICLES TO RETRIEVE
                    </li>
                    <li>
                        WE RECYCLE MATERIALS
                    </li>
                    <li>
                        WE SELL SALVAGED QUALITY AUTO PARTS
                    </li>
                    <li>
                        WE PROVIDE TOWING SERVICES
                    </li>
                    <li>
                        WE ASSIST WITH PAPERWORK & DOCUMENTATION
                    </li>
                    <li>
                        WE PROVIDE VERY FAST DELIVERY TO YOUR DOOR
                    </li>
                </ul>
            </div>

            <div className='carousel fade-in from-right' data-carousel>
                <button className='carousel-btn prev' data-carousel-button="prev"><i className="fas fa-chevron-left"></i></button>
                <button className='carousel-btn next' data-carousel-button="next"><i className="fas fa-chevron-right"></i></button>
                <ul data-slides>
                    <li className='slide'  data-active>
                        <img src={peace5} alt='peace maker gallery #3' />
                    </li>

                    <li className='slide'>
                        <img src={peace2} alt='peace maker gallery #2' />
                    </li>
                    
                    <li className='slide'>
                        <img src={peace4} alt='peace maker gallery #4' />
                    </li>

                    <li className='slide'>
                        <img src={peace3} alt='peace maker gallery #5' />
                    </li>

                    <li className='slide'>
                        <img src={peace6} alt='peace maker gallery #6' />
                    </li>

                    <li className='slide'>
                        <img src={peace7} alt='peace maker gallery #7' />
                    </li>

                    <li className='slide'>
                        <img src={peace8} alt='peace maker gallery #8' />
                    </li>

                    <li className='slide'>
                        <img src={peace9} alt='peace maker gallery #9' />
                    </li>

                    <li className='slide'>
                        <img src={peace10} alt='peace maker gallery #10' />
                    </li>
                </ul>
            </div>

        </div>
    </div>
  )
}