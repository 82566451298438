import React from 'react'
import contactPic from "../img/boywork.png"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import contactCard from '../img/contact-card.png'


export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      phoneNumber:""
    };
    this.handlechanger2 = (v) => {
      this.setState( () => ({
        phoneNumber: v,
      }))
    }
  }

  render() {
    const { status } = this.state;
    const { phoneNumber } = this.state;
    return (
        <div className='contact-div' id='contact'>
            <h2>CONTACT <span className='blue-back'>US</span></h2>
            <p style={{marginBottom:"30px"}} className='fade-in from-bottom'>
              Whether you're looking to sell your car, car parts, or acquire high-quality salvaged components, PeaceMaker 
              Autos LTD is here to assist you. Simply reach out to us, and our dedicated team will ensure a response within 24 hours, 
              making your automotive transactions swift and hassle-free. Your satisfaction is our priority, and we're ready to serve your automotive needs with 
              professionalism and efficiency. Get in touch with us today to experience the PeaceMaker Autos difference.
            </p>
            <div className='contact-flex'>
                <div className='fade-in from-left'>
                    <img src={contactPic} alt='contact pic' className='contact-pic'/>
                </div>

                <div className='contact-main fade-in from-right'>
                    <h2>Call 1-416-300-7648 </h2>
                    <p>or fill out the form below:</p>

                    <div className='contact-req'><span className='req-words'>Request for a spare part / Get in Touch</span></div>
                    <form
                        className="contactForm"
                        onSubmit={this.submitForm}
                        action="https://formspree.io/f/xqkggvpd"
                        method="POST"
                    >
                        <div className="main-form">
                                <input type="text" required className="inputCont " name="name" placeholder="Full name"/><br/>
                                
                                <input type="email" required className="inputCont " name="email" placeholder="myemail@example.com"/><br/>

                                <PhoneInput
                                placeholder="Phone number"
                                value={phoneNumber}
                                // onChange={this.setState({value})}
                                onChange={this.handlechanger2}
                                className="inputCont react-phone"
                                id="phoneNumberId"
                                defaultCountry="CA"
                                />
                                <textarea className="inputCont messageBox" required name="message" placeholder="Describe your Request, we may have it in stock or we will order it specially for you!">

                                </textarea>

                                <div className="send-div">            
                                    {status === "SUCCESS" ? <p className="status">Thank You for the Message!</p> : <button  className="subsub" ><img src={contactCard}/><span className='send'>SEND MESSAGE</span></button>}
                                    {status === "ERROR" && <p className="status">Ooops! There was an error.</p>}
                                </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    data.append("Phone Number", this.state.phoneNumber)
    const xhr = new XMLHttpRequest();
    console.log("HEYYYYYY HERE", data )
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
    this.setState({phoneNumber:null})
    setTimeout(() => { this.setState({status:null}) }, 10000);
  }
}
