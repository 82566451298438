import React from 'react'
import peacePic from '../img/peacepic.png'
import contactCard from '../img/contact-card.png'
import Gallery from './Gallery'
import Contact from './Contact'
import Footer from './Footer'

export default function () {
  return (
    <div>
        <div className='image-section'>
            <img src={peacePic} alt='peacemaker scrap' className='peace-main-pic' />
            <div className='image-writing fade-in from-left'>
                <h2>
                    Transforming Used Car Products into <br/>
                    <span className='blue-background'>Premium Treasures.</span>
                </h2>
                <p>
                    At Peace Maker, we're dedicated to the art of turning used car products into premium goods. <br/>
                    Our mission is to provide a platform where sellers can easily connect with buyers looking for high-quality,<br/>
                    pre-owned car parts and accessories. Whether you're selling or buying, Peace Maker offers a seamless <br/>
                    experience that brings value and reliability to the automotive aftermarket. Join our community today <br/>
                     and discover the potential of your used car products!
                </p>

                <a href='#contact'>
                    <img src={contactCard} className='contact-card'/> <span className='contact-card-word'>Contact US</span>
                </a>
            </div>
        </div>

        <div className='about-us' id="about">
            <h2>About <span className='blue-back'>Us</span></h2>
            <p style={{textAlign:'left'}} className='fade-in from-bottom'>
                PeaceMaker Autos LTD, led by the experienced automotive aficionado, Mr. Lucky, is your go-to 
                destination for all things automobile-related. Our comprehensive range of services includes purchasing 
                used cars, scrapping vehicles for quality parts, and delivering these essential components right to your doorstep.
            </p>

            <p style={{marginTop:'30px', textAlign:'left'}} className='fade-in from-bottom'>
                At PeaceMaker Autos, customer satisfaction reigns supreme. 
                Our unwavering commitment to meeting your automotive needs sets 
                us apart. With decades of industry expertise, we offer reliable 
                solutions and ensure the quality of every part we provide. 
                Convenience is key, and we offer doorstep delivery to make your 
                experience seamless. Our sustainability initiatives underscore our 
                dedication to a greener future. We listen to your requirements, 
                working closely with you to deliver tailored solutions. Join the 
                PeaceMaker Autos family and experience the difference – we're more 
                than just a company; we're a trusted partner dedicated to your automotive peace of mind.
            </p>

            <div className='about-flex'>
                <div className='fade-in from-bottom'>
                    <h1>01</h1>
                    <h2>BUY</h2>
                    <p>We purchase both used cars and salvaged car parts to provide our customers with a comprehensive range of automotive solutions.</p>
                </div>
                <div className='fade-in from-bottom'>
                    <h1>02</h1>
                    <h2>SELL</h2>
                    <p>We sell quality car parts to individuals in need, ensuring they have access to reliable and essential components for their automotive requirements.</p>
                </div>
                <div className='fade-in from-bottom'>
                    <h1>03</h1>
                    <h2>SCRAP</h2>
                    <p>We meticulously salvage car parts, ensuring that valuable components are repurposed and recycled responsibly, contributing to a more sustainable automotive industry.</p>
                </div>
                <div className='fade-in from-bottom'>
                    <h1>04</h1>
                    <h2>DELIVERY</h2>
                    <p>Our speedy doorstep delivery service ensures that you receive your automotive solutions promptly, no matter where you are across Canada.</p>
                </div>
            </div>
        </div>
        <Gallery/>
        <Contact/>
        <Footer/>
    </div>
  )
}
